import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SessionService } from './session.service';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthLoadService {
    constructor(
        private authService: AuthService,
        private sessionService: SessionService
    ) {}

    initModule() {
        this.sessionService.getSession();
    }

    logout() {
        this.authService
            .logout()
            .pipe(
                finalize(() => {
                    this.sessionService.clearSession();
                })
            )
            .subscribe();
    }
}
